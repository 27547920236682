import ajaxify from 'navigation/ajaxify';
import ScreenNavigator from 'screen-navigator';
import {Transitions} from 'screen-navigator';
import BasePage from 'pages/BasePage';
import nav from 'components/nav';
import googleMapsAPI from 'utils/google/maps/google-maps-api';
import debounce from 'debounce';
import loading from 'components/loading';
import 'picturefill';
import 'retina.js';

const PAGES_CLASSES = {
  'page': require('pages/BasePage').default,
  'home-page': require('pages/Home').default,
  'contact-page': require('pages/Contact').default,
  'level-1-page': require('pages/Level1').default,
  'level-2-page': require('pages/Level2').default,
  'blog-page': require('pages/Blog').default,
  'blog-article-page': require('pages/BlogArticle').default,
  'worldwide-page': require('pages/Worldwide').default,
  'error-page': require('pages/Error').default
};

var navigator;

(() => {
  // app settings
  window.app = window.app || {};

  // screen sizes
  app.screenXS = 480;
  app.screenS = 768;
  app.screenM = 1024;
  app.screenL = 1280;
  app.screenXL = 1400;
  app.screenXXL = 1600;

  app.ajaxEnabled = true;
  if (!Modernizr.history) app.ajaxEnabled = false;

  // browsers
  app.isSafari = /Safari/.test(window.navigator.userAgent) && /Apple Computer/.test(window.navigator.vendor);

  // Prevent scroll jump on page navigation back/forward
  // /!\ History API experimental feature
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  // activate scroll anims
  $('html').addClass('in-view-active');

  initNavigator();
  initAjaxify();

  initNav();

  $(window).on('resize', debounce(onResize, 100));
  onResize();

  showPage();
  nav.animateIn();
})();

function initAjaxify(){
  if (!app.ajaxEnabled) return;

  ajaxify.init();
  ajaxify.on('stateChange', onHistoryStateChange);
  ajaxify.on('loading', onAjaxPageLoading);
  ajaxify.on('loaded', onAjaxPageLoaded);
}

function initNavigator(){
  navigator = new ScreenNavigator();
  navigator.transition = Transitions.outThenIn;

  navigator.on('transitionStart', onTransitionStart);
  navigator.on('transitionComplete', onTransitionComplete);
}

function initNav() {
  nav.init();

  nav.on('linkClick', onLinkClick);
}

function showPage(element = null){
  var isAjax = element !== null;

  if (!element) element = $('.page')[0];

  var pageId = 'page';

  if (element.id && PAGES_CLASSES[element.id]){
    pageId = element.id;
  }else{
    var classNames = element.className.split(' ');

    for (var i = 0, n = classNames.length; i < n; i++){
      var className = classNames[i];

      if (className === 'page') continue;

      if (PAGES_CLASSES[className]){
        pageId = className;
        break;
      }
    }
  }

  if (!navigator.getItem(pageId)){
    let pageClass = PAGES_CLASSES[pageId] || BasePage;

    navigator.addItem(pageId, pageClass, {
      events: {
        linkClick: onLinkClick,
        initialized: onPageInitialized
      }
    });
  }

  var currentScreenData = navigator.currentScreen ? navigator.currentScreen.screenData : null;

  navigator.showScreen(pageId, null, {
    arguments: [{
      owner: navigator,
      isAjax: isAjax,
      element: element,
      prevScreenData: currentScreenData
    }]
  });
}

function onHistoryStateChange(state) {
  nav.update();
}

function onAjaxPageLoading() {
  loading.start();
}

function onAjaxPageLoaded(data) {
  var elt = $(data.pageData)[0];

  loading.stop();

  showPage(elt);
}

function onTransitionComplete(){
  nav.updateScroll();
}

function onTransitionStart(){
  nav.show();
}

function onPageInitialized(page) {
  if (page.parentPath){
    nav.update(page.parentPath, true);
  }
}

function onLinkClick(event) {
  if (!app.ajaxEnabled) return;

  ajaxify.onLinkClick(event);
}

function onResize() {
  var width = window.innerWidth;
  var height = window.innerHeight;

  nav.resize(width, height);

  if (navigator && navigator.currentScreen){
    navigator.currentScreen.resize(width, height);
  }
}


